import React from "react";
import { Layout } from "~/components";
import { chardhamLinks } from "~/constants/links";
import { ChardhamHome } from "~/views";

const CharDhamHomePage: React.FunctionComponent = () => {
  return (
    <Layout
      title="Chardham yartra by Helicopter"
      links={chardhamLinks}
      showBanner={false}
      meta="Bookings now open for the Chardham Yatra by Helicopter Sep-Oct 2024. Savour exclusive, and customizable packages at best prices. Book Now!"
    >
      <ChardhamHome />
    </Layout>
  );
};

export default CharDhamHomePage;
